function validateCsvRow (row) {
  if (
    // removing comma check for PfmsAgencyName
    /[,]/g.test(row[0]) ||
    /[,]/g.test(row[1]) ||
    /[,]/g.test(row[2]) ||
    /[,]/g.test(row[3]) ||
    /[,]/g.test(row[5])
  ) {
    return "Remove every single comma ',' and try again"
  } else if (!row[0] || !/^[A-Z]+$/.test(row[0])) {
    return `Column 1 should have a valid StateCode`
  } else if (!row[1] || !/^[A-Z]+$/.test(row[1])) {
    return `Column 2 should have a valid DistrictCode`
  } else if (!row[2] || !/^[\w]*[1-9][0-9]*$/.test(row[2])) {
    return `Column 3 should have a valid TBUCode`
  } else if (!row[3] || !/^[\w]*[1-9][0-9]*$/.test(row[3])) {
    return `Column 4 should have a valid TBUCodeFormatted`
  } else if (!row[4] || !/^[A-Za-z0-9\s/(/)/&//.,-]*$/.test(row[4])) {
    return `Column 5 should have a valid PfmsAgencyName`
  } else if (!row[5] || !/^[A-Za-z0-9\s]*$/.test(row[5])) {
    return `Column 6 should have a valid PfmsAgencyCode`
  }
}

module.exports = validateCsvRow
