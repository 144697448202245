function formatData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  fileRows.reduce((acc, row) => {
    const dataObj = {}
    dataObj[fileRows[0][0]] = row[0]
    dataObj[fileRows[0][1]] = row[1]
    dataObj[fileRows[0][2]] = row[2]
    dataObj[fileRows[0][3]] = row[3]
    dataObj[fileRows[0][4]] = row[4]
    dataObj[fileRows[0][5]] = row[5]
    dataObj.TBUCodeFormatted =
      dataObj.TBUCodeFormatted < 10
        ? `0${dataObj.TBUCodeFormatted}`
        : dataObj.TBUCodeFormatted
    listToSend.push(dataObj)
  })
  return listToSend
}
module.exports = formatData
