<template>
    <LoginForm Heading = 'TU Hierarchy Mapping Update' @submit="submit"/>
</template>
<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { DataForMatomoTracking, initializeNikshayMatomo, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
const validateCsvData = require("./../../utils/validateCsvData")
const formatData = require("./../../utils/formatData")
const csv = require("papaparse")
export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd'
    ])
  },
  mounted: function () {
    initializeNikshayMatomo()
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'success'
    ]),
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      var apiResponse = apiResponseType.FAILED
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = validateCsvData(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = formatData(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/api/ApiAutomatedUpdate/UpdatePfmsAgencyMappings'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data === apiResponseValue.Success){
                apiResponse = apiResponseType.SUCCESS
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'Hangfire Job is triggered.' })
              } else if (jsonUploadResponse.data === apiResponseValue.JsonNotReceived){
                this.setErrorMessage({ isError: true, errMsg: 'File was not received or rejected by Nikshay API' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData('TU Hierarchy Mapping Update', 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              DataForMatomoTracking('TU Hierarchy Mapping Update', apiResponse, data)
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
