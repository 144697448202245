function validateCsvHeadings (row) {
  if (!row[0] || !/^StateCode$/.test(row[0])){
    return "Column 1 heading should be StateCode"
  } else if (!row[1] || !/^DistrictCode$/.test(row[1])){
    return "Column 2 heading should be DistrictCode"
  } else if (!row[2] || !/^TBUCode$/.test(row[2])){
    return "Column 3 heading should be TBUCode"
  } else if (!row[3] || !/^TBUCodeFormatted$/.test(row[3])) {
    return "Column 4 heading should be TBUCodeFormatted"
  } else if (!row[4] || !/^PfmsAgencyName$/.test(row[4])) {
    return "Column 5 heading should be PfmsAgencyName"
  } else if (!row[5] || !/^PfmsAgencyCode$/.test(row[5])) {
    return "Column 6 heading should be PfmsAgencyCode"
  }
}

module.exports = validateCsvHeadings
